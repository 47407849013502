export const EXTERNAL_LINK_MODAL_CONTENT = {
  ARIA: {
    closeModal: 'Cerrar Modal',
    title: 'Si haces clic en este enlace, saldrás de este sitio.',
  },
  title: 'Si haces clic en este enlace, saldrás de este sitio.',
  children: (
    <>
      <p>Este enlace te llevará a un sitio ajeno a Organon.</p>
      <p>
        Organon no revisa ni controla el contenido de ningún sitio que no sea de
        Organon. Organon no respalda ni es responsable de la exactitud, el
        contenido, las prácticas o las normas de ningún sitio que no sea de
        Organon.
      </p>
    </>
  ),
  proceedButton: {
    children: 'Continuar',
    'data-design-label': 'Continuar',
    href: 'https://www.fda.gov/safety/medwatch-fda-safety-information-and-adverse-event-reporting-program',
    caption: 'Sitio en inglés',
  },
  cancelButton: {
    children: 'Cancelar',
    'data-design-label': 'Cancelar',
  },
};
