import { ISI_BAR_CONTENT } from 'Custom/ISIBar/es/ISIBar.utils';
import { ISI_CONTENT } from 'Custom/ISI/es/ISI.utils';

export const ISI_MODAL_CONTENT = {
  moduleName: 'Indicación y Información de Seguridad Importante',
  ARIA: {
    closeModal: 'Cerrar Modal',
    title: 'Indicación y Información de Seguridad Importante',
  },
  indicationTitle: 'Indicación',
  isiTitle: 'Información de Seguridad Importante',
  indicationContent: ISI_BAR_CONTENT.indicationIntro,
  content: ISI_CONTENT,
};
